<template>
    <div>
        <div>
            <div>Chỗ bạn chọn hiện vẫn đang trong phiên thanh toán.</div>
            <div>Bạn có muốn tiếp tục thực hiện thanh toán?</div>
        </div>
        <div class="divider pt-3 pb-2"></div>
        <div class="font-14 font-bold pt-3">Thông tin thanh toán</div>
        <div class="container-xs">
            <div class="row pt-2 align-center justify-space-between">
                <div>Hình thức thanh toán:</div>
                <div>{{ model.HinhThucThanhToan }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Số điện thoại:</div>
                <div>{{  $Helper.formatPhone(model.SoDienThoai) }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Họ và tên:</div>
                <div>{{ model.HoTen }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Vị trí chỗ:</div>
                <div>{{ model.ViTriCho }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Tổng tiền:</div>
                <div>{{ `${$MoneyFormat(model.TongTien)}đ` }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Chiết khấu:</div>
                <div>{{ `${$MoneyFormat(model.ChietKhau)}đ` }}</div>
            </div>
            <div class="row pt-3 align-center justify-space-between">
                <div>Thực thu:</div>
                <div>{{ `${$MoneyFormat(model.ThucThu)}đ` }}</div>
            </div>
        </div>
        <div class="row container-xs mt-3">
            <div class="shrink danger--text pr-2">(*)</div>
            <div class="grow font-12 font-italic">
                <div>
                    Chọn "Tiếp tục thanh toán" để thực hiện tiếp phiên thanh
                    toán cũ
                </div>
                <div class="pt-2">
                    Chọn "Hủy thanh toán" để bán vé cho hành khách khác
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                TongTien: 0,
                ChietKhau: 0,
                ThucThu: 0,
                HinhThucThanhToan: "",
                SoDienThoai: "",
                HoTen: "",
                Email: "",
                ViTriCho: "",
            },
        };
    },
    // watch: {
    //     Data() {
    //         if (this.Data) {
    //             this.model = this.Data;
    //         }
    //     },
    // },
};
</script>
<style lang="scss" scoped>
.divider {
    border-bottom: 1px solid $border;
}
</style>
<style scoped>
.dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-fullscreen-width{
    border-radius: unset !important;
}
</style>