<template>
    <ion-page>
        <ThanhTieuDeVue TieuDe="Lịch sử chuyến đi">
            <template #center v-if="hienThiTimKiem"><div></div></template
            ><template #after
                ><div>
                    <DxButton
                        type="default"
                        styling-mode="outlined"
                        icon="mdi mdi-youtube"
                        class="pr-2"
                    /></div></template
        ></ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <div
                style="height: 32px; width: 100%; z-index: 5"
                class="shadow row justify-center align-center"
            >
                {{ `Khách trên chuyến: Chưa có dữ liệu` }}
            </div>
            <div v-if="data.DanhSachTangXe.length > 0">
                <div style="padding-top: 16px">
                    <div style="position: relative">
                        <!-- ====================================Sơ đồ chỗ========================================= -->
                        <div
                            style="
                                position: absolute;
                                top: 32px;
                                margin-left: auto;
                                margin-right: auto;
                                left: 0;
                                right: 0;
                                z-index: 4;
                                width: 130px;
                            "
                        >
                            <div class="row justify-space-around">
                                <div
                                    v-for="(item, index) in data.DanhSachTangXe"
                                    :key="index"
                                    @click="onTitleClick(item)"
                                >
                                    <div
                                        :style="`width: 32px;
                                                    height: 32px;
                                                    border-radius:50px;
                                                    ${
                                                        !item.active
                                                            ? 'border:1px solid #707070'
                                                            : ''
                                                    }`"
                                        :class="`row justify-center align-center ${
                                            item.active
                                                ? 'primary--bg white--text'
                                                : 'white-bg'
                                        }`"
                                    >
                                        {{
                                            item.tenTang
                                                .replace("Tầng ", "")
                                                .trim() || item.tenTang
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 90%; margin: 0 auto">
                            <SoDoChoVue
                                @SoDoCho="soDoCho"
                                :usecheckbox="false"
                                :usebackground="true"
                                width="90%"
                                :ChoTheoSdt="data.DanhSachChoNgoiTheoSdt"
                                :TangXe="model.TangXe"
                                height="calc(100vh - 300px)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="data.DanhSachTangXe.length == 0"
                style="opacity: 0.6; height: 60vh"
                class="row justify-center align-center"
            >
                Không có dữ liệu
            </div>
            <div
                v-else
                style="height: 80%"
                class="column justify-center align-center"
            >
                <div style="opacity: 0.6">Lỗi hệ thống.</div>
                <div style="opacity: 0.6">Vui lòng thử lại!</div>
                <ion-button
                    color="primary"
                    expand="block"
                    class="mt-2"
                    @click="layDanhSachTangXe()"
                >
                    Thử lại
                </ion-button>
            </div>
            <!-- ====================================Popup xác nhận hành khách xuống xe ========================================= -->
            <PopupVue
                height="70%"
                v-model:dialog="dialogLichSu"
                :title="titleLichSuXe"
                buttonTextRight="Đóng"
                :useButtonLeft="false"
                @close="closeLichSu"
                :fullWidth="true"
                class="list-style"
                :key="chinhSuaSoatVe"
            >
                <template #content
                    ><div>
                        <DxList
                            :data-source="data.LichSu"
                            height="calc(100% - 125px)"
                            page-load-mode="scrollBottom"
                            key-expr="id"
                            :hoverStateEnabled="false"
                            :focusStateEnabled="false"
                            :activeStateEnabled="false"
                            no-data-text="Không có dữ liệu!"
                            class="list-style"
                            :allow-item-deleting="true"
                            item-delete-mode="slideItem"
                        >
                            <template #item="{ data: item }">
                                <div>
                                    <div
                                        class="container-md"
                                        style="
                                            border-radius: 5px;
                                            box-shadow: 2px 4px 9px #ddd;
                                            position: relative;
                                        "
                                    >
                                        <ItemLichSuVue :item="item" />
                                    </div>
                                </div> </template
                        ></DxList>
                    </div>
                </template>
            </PopupVue>
            <!-- ====================================Popup Thông báo ========================================= -->
            <PopupVue
                height="38%"
                v-model:dialog="dialogThongBao"
                title=""
                :useButtonLeft="false"
                :fullWidth="true"
                @close="
                    () => {
                        dialogThongBao = false;
                    }
                "
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import SoDoChoVue from "../../../src/components/SoDoCho";
import ThongTinSoatVeVue from "../../../src/components/ThongTinSoatVe";
import ThongBaoHuyThanhToanVue from "../../../src/components/ThongBaoHuyThanhToan";
import ToastContentVue from "../../../src/components/_Common/ToastContent";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import XacNhanHanhKhachXuongXeVue from "../../../src/components/XacNhanHanhKhachXuongXe";
import XacNhanHanhKhachXuongThucHienChuyenDiVue from "../../../src/components/XacNhanHanhKhachXuongThucHienChuyenDi";
import ItemLichSuVue from "../../../src/components/BaoCao/ItemLichSu";
import PhuongTien, { ChoNgoi } from "../../../class/PhuongTien";
import { IonButton } from "@ionic/vue";
import {
    DxTextBox,
    DxTabPanel,
    DxButton,
    DxToast,
    DxList,
} from "devextreme-vue";
import { IonContent, IonPage } from "@ionic/vue";
import { mapGetters, mapMutations } from "vuex";
export default {
    components: {
        ThanhTieuDeVue,
        SoDoChoVue,
        ThongBaoVue,
        PopupVue,
        XacNhanHanhKhachXuongXeVue,
        XacNhanHanhKhachXuongThucHienChuyenDiVue,
        ThongTinSoatVeVue,
        ThongBaoHuyThanhToanVue,
        ToastContentVue,
        DxTextBox,
        DxTabPanel,
        DxButton,
        DxToast,
        ItemLichSuVue,
        DxList,
        IonContent,
        IonPage,
        IonButton,
    },
    data() {
        return {
            dialogThongBaoHuyThanhToan: false,
            dialogLichSu: false,
            dialogSoatVe: false,
            dialogHKKhongDi: false,
            dialogThongBao: false,
            hienThiTimKiem: false,
            tempViTriDaChon: null,
            tempReLoadTangXe: 0,
            tempCapNhatChuThich: 0,
            titleLichSuXe: "",
            searchButton: [
                {
                    name: "search",
                    location: "after",
                    options: {
                        icon: "search",
                        type: "normal",
                        onClick: () => {
                            setTimeout(() => {
                                this.$refs.TimKiemTheoSoDienThoai.instance.focus();
                            }, 500);
                            this.hienThiTimKiem = !this.hienThiTimKiem;
                            if (!this.hienThiTimKiem) {
                                this.model.SoDienThoai = "";
                                this.timKiemTheoSoDienThoai(); //Bỏ tìm theo số điện thoại
                            }
                        },
                    },
                },
            ],
            model: { TangXe: 1, SoDienThoai: "" },
            data: {
                ChoDaChon: [],
                LichSu: [
                    // {
                    //     SoDienThoai: "123",
                    //     Ten: "123",
                    //     GiaVe: 100000,
                    //     BenDi: "123",
                    //     BenDen: "123",
                    //     ThoiGianBatDau: "123",
                    // },
                    // {
                    //     SoDienThoai: "123",
                    //     Ten: "123",
                    //     GiaVe: 100000,
                    //     BenDi: "123",
                    //     BenDen: "123",
                    //     ThoiGianBatDau: "123",
                    // },
                    // {
                    //     SoDienThoai: "123",
                    //     Ten: "123",
                    //     GiaVe: 100000,
                    //     BenDi: "123",
                    //     BenDen: "123",
                    //     ThoiGianBatDau: "123",
                    // },
                    // {
                    //     SoDienThoai: "123",
                    //     Ten: "123",
                    //     GiaVe: 100000,
                    //     BenDi: "123",
                    //     BenDen: "123",
                    //     ThoiGianBatDau: "123",
                    // },
                ],
                DanhSachTangXe: [],
                MaTranCho: [],
                ThongTinKhachHang: null,
                DanhSachChoNgoiTheoSdt: [],
                ThongTinVe: {
                    LinkTraCuu: "",
                    MaUngDung: "",
                    MaChuyenDi: "",
                    MaDatCho: "",
                },
            },
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            toast: { visible: false, message: "", type: "success" },
        };
    },
    computed: {
        ...mapGetters("Ve", ["chinhSuaSoatVe"]),
    },
    methods: {
        ...mapMutations("Ve", ["setChinhSuaSoatVe", "resetSoDoCho"]),
        onTitleClick(item) {
            this.data.DanhSachTangXe.forEach((e) => {
                if (e.idTang == item.idTang) {
                    e.active = true;
                } else {
                    e.active = false;
                }
            });
            this.chonTangXe(item);
        },
        async soDoCho(item, data) {
            this.titleLichSuXe = "Lịch sử bán vé " + item.KyHieu;
            this.dialogLichSu = true;
        },
        async layDanhSachTangXe() {
            //Lấy ds tầng xe
            try {
                let DanhSachTangXe = await new PhuongTien().layDanhSachTangXe(
                    this.$route.query.guidChuyenDi
                );
                if (DanhSachTangXe.length > 0) {
                    DanhSachTangXe.forEach((e) => {
                        e.text = e.tenTang;
                        e.id = e.idTang;
                    });
                    this.model.TangXe = DanhSachTangXe[0];
                    DanhSachTangXe[0].active = true;
                    this.data.DanhSachTangXe = DanhSachTangXe;
                    this.chonTangXe(DanhSachTangXe[0]);
                }
            } catch (error) {
                this.thongBaoLoi("", error);
            }
        },
        chonTangXe(item) {
            this.tempReLoadTangXe++;
            this.drawer = false;
            this.tempViTriDaChon = false;
            this.model.TangXe = item;
        },
        closeLichSu() {
            this.dialogLichSu = false;
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.dialogHKKhongDi = false;
            this.dialogLichSu = false;
            this.dialogSoatVe = false;
            this.thongBao(
                "Error",
                message || "Đã xảy ra lỗi. Vui lòng thử lại"
            );
            this.capNhatTrang();
        },
        capNhatTrang() {
            this.tempCapNhatChuThich++;
            this.resetSoDoCho();
            this.tempReLoadTangXe++;
        },
    },
    async created() {
        this.layDanhSachTangXe();
    },
    beforeMount() {
        localStorage.removeItem("ChoDaChon");
        localStorage.removeItem("ThongTinBanVe");
    },
};
</script>
<style scoped>
/* style tìm kiếm bằng sdt */
>>> .rounder {
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px rgb(255 255 255) !important;
}
>>> .rounder .dx-button-content {
    position: fixed;
    right: 6px;
    height: auto;
}
>>> .rounder .dx-texteditor-input-container > div {
    color: white;
    top: 0px !important;
    left: 16px;
}
>>> .rounder .dx-texteditor-input-container > input {
    padding: 16px 12px 18px !important;
    color: white;
}
>>> .hidden-input {
    box-shadow: unset !important;
}
>>> .hidden-input .dx-texteditor-input-container {
    display: none !important;
}
>>> .list-style .dx-list-item-content {
    padding: 8px 5px 13px;
}
>>> .list-style .dx-list-item,
>>> .list-style .dx-list-item-content {
    white-space: unset !important;
}
</style>