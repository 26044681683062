<template>
    <div style="height: auto">
        <DxValidationGroup ref="formValidation">
            <div v-show="!chinhSuaSoatVe">
                <div
                    class="
                        row
                        justify-space-between
                        align-center
                        font-bold font-16
                    "
                    :class="model.soDienThoai ? 'primary--text' : ''"
                >
                    <a :href="`tel:${model.soDienThoai}`">
                        <div class="row align-center">
                            <i
                                class="mdi mdi-card-account-phone-outline pr-3"
                            ></i>
                            <div v-if="model.soDienThoai">
                                {{ $Helper.formatPhone(model.soDienThoai) }}
                            </div>
                            <div v-else class="font-12 color-087 font-regular">
                                Nhập số điện thoại giúp kiểm soát dịch Covid-19!
                            </div>
                        </div>
                    </a>
                    <ion-button
                        color="primary"
                        fill="clear"
                        size="small"
                        @click="setChinhSuaSoatVe(true)"
                    >
                        <ion-icon :icon="pencil" class="font-24"></ion-icon>
                    </ion-button>
                </div>
                <div class="row pb-1 align-center font-bold font-16">
                    <i class="mdi mdi-account-circle-outline pr-3"></i>
                    <div>{{ model.hoTen || "" }}</div>
                </div>
            </div>
            <div v-show="chinhSuaSoatVe">
                <div class="pb-4">
                    <div class="font-12 text-xs-left">Điện thoại</div>
                    <DxTextBox
                        class="mb-2"
                        v-model="model.soDienThoai"
                        validationMessageMode="always"
                        mode="tel"
                        :tabIndex="1"
                        :onEnterKey="onEnterKey"
                        ref="refTab1"
                        styling-mode="underlined"
                        valueChangeEvent="keyup"
                    >
                        <DxValidator>
                            <!-- <DxRequiredRule
                            message="Vui lòng nhập số điện thoại để tiếp tục mua vé!"
                        /> -->
                            <DxStringLengthRule
                                :max="13"
                                message="Số điện thoại không được vượt quá 13 ký tự!"
                            />
                            <DxNumericRule
                                message="Số điện thoại phải là số!"
                            />
                            <DxRangeRule
                                :min="0"
                                message="Số điện thoại phải là số!"
                            />
                            <DxPatternRule
                                :pattern="phonePattern"
                                message="Vui lòng nhập đúng định dạng số điện thoại để tiếp tục mua vé!"
                            />
                        </DxValidator>
                    </DxTextBox>
                </div>
                <div class="pb-4">
                    <div class="font-12 text-xs-left">Họ tên</div>
                    <DxTextBox
                        v-model="model.hoTen"
                        mode="text"
                        :tabIndex="2"
                        styling-mode="underlined"
                        :onEnterKey="onEnterKey"
                        ref="refTab2"
                        validationMessageMode="always"
                    >
                        <DxValidator>
                            <DxStringLengthRule
                                :max="128"
                                message="Họ tên không được vượt quá 128 ký tự!"
                            />
                        </DxValidator>
                    </DxTextBox>
                </div>
            </div>
            <div
                :class="`row pb-1 align-center justify-space-between ${
                    chinhSuaSoatVe ? 'disabled--text' : ''
                }`"
            >
                <div>
                    <div class="row align-center">
                        <i class="mdi mdi-currency-usd pr-2"></i>
                        <div>Thanh toán:</div>
                    </div>
                </div>
                <div
                    :style="`color:${
                        !chinhSuaSoatVe ? model.trangThaiThanhToan.maMau : ''
                    }`"
                    :class="chinhSuaSoatVe ? 'disabled--text' : ''"
                >
                    {{ model.trangThaiThanhToan.tenTrangThai || "" }}
                </div>
            </div>
            <div
                :class="`row align-center justify-space-between ${
                    chinhSuaSoatVe ? 'disabled--text' : ''
                }`"
            >
                <div>
                    <div class="row align-center">
                        <i class="mdi mdi-google-maps pr-2"></i>
                        <div>Điểm xuống:</div>
                    </div>
                </div>
                <!-- :style="`color:${
                        !chinhSuaSoatVe ? model.trangThaiTiemPhong.maMau : ''
                    }`" -->
                <div :class="chinhSuaSoatVe ? 'disabled--text' : ''">
                    {{ model.tenDiemXuong || "" }}
                </div>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import { DxTextBox, DxValidator, DxValidationGroup } from "devextreme-vue";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxPatternRule,
    DxRangeRule,
} from "devextreme-vue/data-grid";
import { DxNumericRule } from "devextreme-vue/validator";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { pencil } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/vue";
import { ref, reactive, watchEffect } from "vue";
import { ChoNgoi } from "../../class/PhuongTien";
export default {
    components: {
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidationGroup,
        IonButton,
        IonIcon,
        DxRangeRule,
    },
    props: {
        Data: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        let model = reactive({
            hoTen: "",
            lichSuSoatVe: [],
            maChuyenDi: "",
            maDatCho: "",
            tenDiemXuong: "",
            guidDiemXuong: "",
            soDienThoai: "",
            soGhe: "",
            soVe: "",
            trangThaiThanhToan: { maMau: "", tenTrangThai: "" },
            trangThaiTiemPhong: { maMau: "", tenTrangThai: "" },
        });
        let diemXuongDangChon = reactive({
            tenDiemDung: null,
            guidDiemXuong: null,
        });
        let phonePattern = ref(
            /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
        );
        let viTriDangChon = ref(null);
        watchEffect(() => {
            console.log(
                "%c this ",
                "color: white; background-color: #95B46A",
                "=========>",
                this
            );
            model = props.Data;
            console.log(
                "🚀 ~ file: XacNhanHanhKhachXuongXe.vue ~ line 194 ~ watchEffect ~ model",
                model
            );
        });
        return {
            pencil,
            model,
            phonePattern,
            viTriDangChon,
            diemXuongDangChon,
        };
    },
    computed: {
        ...mapGetters("Ve", ["chinhSuaSoatVe", "chuyenDiGanDay"]),
    },
    methods: {
        ...mapMutations("Ve", ["setChinhSuaSoatVe"]),
        ...mapActions("Ve", ["layDiemXuongHienTaiCuaChoNgoiChuyenDi"]),
        onEnterKey(e) {
            let index = e.event.currentTarget.tabIndex;
            if (index) {
                let ref = this.$refs["refTab" + (index + 1)];
                ref ? ref.instance.focus() : null;
            }
        },
        async suaThongTin() {
            let rs = this.$refs.formValidation.instance.validate();
            if (rs.isValid) {
                let query = {
                    guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                    guidChoNgoi: this.viTriDangChon.id,
                    tenKhachHang: this.model.hoTen,
                    soDienThoai: this.model.soDienThoai.trim(),
                    tenDiemXuong: this.model.tenDiemXuong,
                    guidDiemXuong: this.model.guidDiemXuong,
                    maDatCho: this.model.maDatCho,
                };
                try {
                    this.$startLoading;
                    let rs = await $Core.Api.DonHang(
                        this.$t("url.LuuThongTinKhachHang")
                    ).Post(query);
                    this.$stopLoading;
                    this.$emit("rsSuaThongTinSoatVe", rs.StatusCode);
                } catch (error) {
                    this.$stopLoading;
                    this.$emit(
                        "rsSuaThongTinSoatVe",
                        rs.StatusCode,
                        rs.StatusMessage
                    );
                }
            } else {
                this.$Helper.autoFocusError(rs);
            }
        },
    },
    async mounted() {
        setTimeout(() => {
            if (this.chinhSuaSoatVe) {
                let refSdt = this.$refs.refTab1.instance;
                if (refSdt) {
                    refSdt.focus();
                }
            }
        }, 500);
        try {
            let vtdc = await new ChoNgoi().viTriDangChon(true);
            if (!vtdc) {
                console.log("loi");
                return false;
            }
            this.viTriDangChon = vtdc;
        } catch (error) {
            console.log(
                "🚀 ~ file: ThongTinSoatVe.vue ~ line 280 ~ mounted ~ error",
                error
            );
        }
    },
};
</script>