<template>
    <div :class="`sodoghe-style ${direction == 'column' ? '' : 'row'}`">
        <div class="buonglai"></div>
        <div class="wheel-font-left"></div>
        <div class="wheel-font-right"></div>
        <div class="wheel-back-left"></div>
        <div class="wheel-back-right"></div>
        <div class="guong-trai"></div>
        <div class="guong-phai"></div>

        <div
            :class="`justify-space-around dauxe pb-1 ${
                direction == 'column' ? 'row' : 'column'
            }`"
            style="z-index: 4"
        >
            <div
                :class="`denxe ${direction == 'column' ? '' : 'rotate'}`"
                style="width: 40px; height: 32px"
            ></div>
            <div
                :class="`denxe ${direction == 'column' ? '' : 'rotate'}`"
                style="width: 40px; height: 32px"
            ></div>
        </div>

        <div
            :class="`row justify-space-between ${
                direction == 'column' ? 'row dauxe' : 'column'
            }`"
            :style="direction == 'column' ? '' : 'flex-direction: column-reverse'"
            style="padding: 0px 8px"
        >
            <div
                style="
                    max-width: 48px;
                    margin: 0 8px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                "
                :style="`width: calc((100%/${TangXe.soCot}) - 16px);`"
            >
                <div class="donghotrai"></div>
                <div class="donghoto"></div>
                <div class="donghophai"></div>
                <div class="bangdientu"></div>
                <!-- <div class="ghelai"></div>
                <div class="tayghelaixe"></div>
                <div class="lungghelaixe"></div> -->
                <i
                    :class="`mdi mdi-steering ${direction == 'column' ? '' : 'rotate'}`"
                    style="position: relative; z-index: 2"
                    :style="`opacity: ${TangXe.tenTang == 'Tầng 1' ? 1 : 0.3}`"
                ></i>
            </div>
            <div
                style="max-width: 48px; margin: 0 8px"
                :style="`width: calc((100%/${TangXe.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 48px; margin: 0 8px"
                :style="`width: calc((100%/${TangXe.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 48px; margin: 0 8px"
                :style="`width: calc((100%/${TangXe.soCot}) - 16px);`"
            ></div>
            <div
                style="max-width: 48px; margin: 0 8px"
                :style="`width: calc((100%/${TangXe.soCot}) - 16px);`"
            ></div>
            <!-- <div
                :style="direction == 'column' ? 'width:40%' : 'height:70%'"
            ></div>
            <i
                :class="`mdi mdi-exit-to-app pr-5 cuaravao ${
                    direction == 'column' ? '' : 'rotate'
                }`"
            ></i> -->
        </div>

        <div
            style="
                margin-top: -16px;
                height: calc(100% - 48px);
                position: relative;
                z-index: 1;
            "
        >
            <div
                class="xs12 scroll-sodoghe-style"
                style="overflow: auto; height: 100%; padding: 4px 8px"
                :style="
                    direction == 'column' ? 'padding-botom:30px' : 'padding-right:30px'
                "
            >
                <div
                    v-for="(dong, i) in TangXe.soHang"
                    :key="i"
                    class="full-width hang-xe row justify-space-between"
                    :style="`height: calc(100%/${TangXe.soHang} - 16px);`"
                    :class="TangXe.soHang - 1 == i ? '' : 'mb-3'"
                >
                    <div
                        v-for="(cot, j) in TangXe.soCot"
                        :key="j"
                        :style="`${item.style}; width: calc((100%/${TangXe.soCot}) - 16px);`"
                        class="vitri"
                    >
                        <div
                            @click="chonCho(dong, cot)"
                            @dblclick="doiTrangThaiCho(dong, cot)"
                            style="position: relative; z-index: 2"
                        >
                            <div v-html="hienThiCho(dong, cot)" class="full-height"></div>
                            <DxCheckBox
                                v-if="usecheckbox"
                                :value="checked(dong, cot)"
                                :read-only="true"
                                class="pa-1 checkbox-style"
                                style="position: absolute; top: 0; left: 64px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="thanhxe"
            :style="`${direction == 'column' ? 'top:19px' : 'left: 31px'}`"
        ></div>
    </div>
</template>
<script>
import { ChoNgoi } from "../../class/PhuongTien";
import { DxCheckBox } from "devextreme-vue";
import { mapGetters } from "vuex";
export default {
    props: {
        showallselected: { type: Boolean, default: false },
        reset: { type: Number, default: 0 },
        reloadData: { type: Number, default: 0 },
        height: { type: String, default: "400px" },
        width: { type: String, default: "100%" },
        item: {
            type: Object,
            default: () => ({ height: "40px", width: "100%", style: "" }),
        },
        direction: { type: String, default: "column" },
        disabled: { type: Boolean, default: false },
        useOpacity: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        usecheckbox: { type: Boolean, default: false },
        useborder: { type: Boolean, default: false },
        usebackground: { type: Boolean, default: false },
        useHighlights: { type: Boolean, default: false },
        border: { type: String, default: "2px solid red" },
        backgroundcolor: { type: String, default: "red" },
        TangXe: { type: Object, default: () => ({ soCot: 0, soHang: 0 }) },
        ChoTheoSdt: { type: Array, default: () => [] },
    },
    data() {
        return {
            ChiHienThiViTriDangChon: false,
            data: {
                DanhSachCho: [],
                ChoDaChon: [],
            },
            model: {
                ChoDaChon: false,
            },
        };
    },
    components: {
        DxCheckBox,
    },
    computed: {
        DanhSachCho() {
            return this.data.DanhSachCho;
        },
        ...mapGetters("Ve", ["resetSoDoCho", "reloadSoDoCho", "chuyenDiGanDay"]),
    },
    methods: {
        hienThiCho(dong, cot) {
            let item = this.data.DanhSachCho.filter(
                (f) => f.Dong === dong && f.Cot === cot,
            );
            let ttchoTrong = this.$t("trangThai.Trong");
            let ttchoDaNgoi = this.$t("trangThai.DaNgoi");
            let Cho = item.length > 0 ? item[0] : null;
            let styl = `;padding: 4px;border-radius: 7px;text-align: center;width: ${this.item.width};height: ${this.item.height};min-width: 34px;min-height: 34px;`;
            if (item.length > 0) {
                let style;

                //style cho dang chon
                style = `
                    <div class="khungghe" 
                        style="
                            z-index: 0;
                            width: calc(100% + 8px);
                            height: calc(100% - 4px);
                            background: #d6d6d6;
                            position: absolute;
                            top: 8px;
                            left: -4px;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            ${
                                Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuNhaVeSinh") ||
                                Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuCua")
                                    ? `
                                        width: 100%;
                                        left: 0;
                                        opacity: 0.5;

                                    `
                                    : ``
                            }
                        "
                    ></div>

                    <div class="lungghe"
                        style="
                            position: absolute;
                            z-index: 3;
                            background: #d6d6d6;
                            width: calc(100% - 16px);
                            height: 4px;
                            bottom: 0;
                            left: 8px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        "
                    ></div>
                    <div class='row justify-center align-center' 
                        style='
                        ${
                            this.usebackground && Cho.ChoDaChon
                                ? "background-color:" +
                                  this.backgroundcolor +
                                  " !important;border:1px solid " +
                                  this.backgroundcolor +
                                  " !important;"
                                : ""
                        }
                        background-color:${Cho.Disabled ? "#ddd" : Cho.MauNen};
                        color:${
                            Cho.TrangThai == ttchoTrong ? "black !important;" : "white;"
                        }
                        border:1px solid ${Cho.MauNen};
                        border: ${Cho.TrangThai == ttchoTrong ? "1px solid #ddd;" : ""};
                        ${
                            this.useborder && Cho.ChoDaChon
                                ? "border:" + this.border + " !important;"
                                : ""
                        }
                        opacity: ${
                            !Cho.HienThi
                                ? "0.2;"
                                : this.useOpacity && Cho.ChoDaChon
                                ? "0.2;"
                                : ""
                        }
                        ${styl}
                        ${
                            Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuNhaVeSinh") ||
                            Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuCua")
                                ? `
                                    z-index: 2;
                                    background: #d6d6d6;
                                    color: red !important;
                                    border: 1px solid #d6d6d6;
                                    position: relative;
                                    top: 4px;
                                    border-radius: 8px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    opacity: 0.7;
                            `
                                : ""
                        }
                        '>
                        ${
                            Cho.TrangThai == ttchoDaNgoi
                                ? "<i class='mdi mdi-account-check'></i>"
                                : Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuNhaVeSinh")
                                ? "<i class='mdi mdi-toilet'></i>"
                                : Cho.KyHieu == this.$t("KyHieuChoNgoi.KyHieuCua")
                                ? "<i class='mdi mdi-door-open'></i>"
                                : Cho.KyHieu
                        }
                    </div>`;
                return style;
            }
            return `<div class='row justify-center align-center' style='${styl}; opacity: 0'>x</div>`;
        },
        doiTrangThaiCho(dong, cot) {
            console.log("%c  ", "color: white; background-color: #95B46A", "=========>");
        },
        chonCho(dong, cot) {
            //Không được chọn
            if (this.readonly) {
                return;
            }

            //Lấy chỗ
            let item = this.data.DanhSachCho.filter(
                (f) => f.Dong === dong && f.Cot === cot,
            )[0];
            if (item.LoaiCho == parseInt(this.$i18n.t("LoaiCho.LaiXe"))) {
                return;
            }
            if (!item) {
                return;
            }
            if (item.KhongDuocChon) {
                return;
            }
            if (
                item &&
                (item.KyHieu == this.$t("KyHieuChoNgoi.KyHieuNhaVeSinh") ||
                    item.KyHieu == this.$t("KyHieuChoNgoi.KyHieuCua"))
            ) {
                return;
            }
            this.capNhatCho(item);
            //Lưu lại chỗ đã chọn để khi chọn tầng không bị mất
        },
        capNhatCho(item, chon) {
            if (!item) {
                return;
            }
            //Tải lại
            // if (this.useHighlights) {
            //     this.data.DanhSachCho.forEach((e) => {
            //         if (!e.ChoDaChon) {
            //             e.Disabled = true;
            //         }
            //     });
            // }
            this.data.DanhSachCho = this.data.DanhSachCho.map((e) => {
                if (item.id == e.id) {
                    e.ChoDaChon = chon ? chon : true;
                    e.HienThi = this.ChiHienThiViTriDangChon ? !e.HienThi : true;
                } else {
                    e.ChoDaChon = chon ? chon : false;
                }
                return e;
            });
            this.luuCacChoDaChon(item);
        },
        response(item, DanhSachChoDaChon) {
            //Gửi đi
            this.$emit("SoDoCho", item, DanhSachChoDaChon);
            this.emitter.emit("SoDoCho", item, DanhSachChoDaChon);
        },
        checked(dong, cot) {
            let item = this.data.DanhSachCho.filter(
                (f) => f.Dong === dong && f.Cot === cot,
            );
            if (item.length > 0) {
                if (item[0]) {
                    return item[0].ChoDaChon;
                }
            }
            return false;
        },
        chiHienThiViTriDaChon(hienthi) {
            this.data.DanhSachCho.forEach((e) => {
                if (!e.ChoDaChon) {
                    e.HienThi = !hienthi;
                }
            });
        },
        luuCacChoDaChon(item) {
            this.response(item, []);
            localStorage.setItem("ChoDaChon", JSON.stringify([item]));
            return;
            // Code cũ của thảo
            let ChoDaChonTruocDo = JSON.parse(localStorage.getItem("ChoDaChon"));
            let DanhSachChoDaChon = ChoDaChonTruocDo ? ChoDaChonTruocDo : [];
            this.data.DanhSachCho.forEach((e) => {
                if (e.id === item.id) {
                    if (e.ChoDaChon) {
                        DanhSachChoDaChon.push(e);
                    } else {
                        DanhSachChoDaChon = DanhSachChoDaChon.filter((f) => f.id != e.id);
                    }
                }
            });
            //Giới hạn chọn được chọn
            let SoChoToiDa = 1; // parseInt(new TaiKhoan().cauHinhChung().soChoToiDa);
            if (DanhSachChoDaChon.length > SoChoToiDa) {
                this.capNhatCho(item, false);
                this.response(item, DanhSachChoDaChon);
                return;
            }
            //Gửi dữ liệu global
            this.emitter.emit("SoDoCho_ChoDangChon", item);
            this.response(item, DanhSachChoDaChon);
            localStorage.setItem("ChoDaChon", JSON.stringify(DanhSachChoDaChon));
        },
        giuCacChoDaChonTruocDo(data) {
            let ChoDaChonTruocDo = JSON.parse(localStorage.getItem("ChoDaChon"));
            if (
                !ChoDaChonTruocDo ||
                (Array.isArray(ChoDaChonTruocDo) && ChoDaChonTruocDo.length == 0)
            ) {
                return data;
            }
            data.forEach((e) => {
                ChoDaChonTruocDo.forEach((c) => {
                    if (e.id === c.id) {
                        e.ChoDaChon = c.ChoDaChon;
                    }
                });
            });
            return data;
        },
        resetData() {
            localStorage.removeItem("ChoDaChon");
            this.data.DanhSachCho = this.data.DanhSachCho.map((e) => {
                e.ChoDaChon = false;
                e.HienThi = true;
                return e;
            });
            //Gửi dữ liệu global
            this.emitter.emit("SoDoCho_ChoDangChon", null);
        },
    },
    watch: {
        showallselected: {
            handler: function (val) {
                this.ChiHienThiViTriDangChon = val;
                this.chiHienThiViTriDaChon(val);
            },
            deep: true,
            immediate: true,
        },
        reloadSoDoCho: {
            handler: function () {
                //Lấy ma trận chỗ
                setTimeout(async () => {
                    let MaTranCho = await new ChoNgoi().layMaTranCho(
                        this.chuyenDiGanDay.guidChuyenDi ||
                            this.$route.query.guidChuyenDi,
                        this.TangXe.idTang,
                    );
                    if (MaTranCho.length == 0) {
                        return;
                    }
                    let maTranCho;
                    maTranCho = MaTranCho.map((e) => {
                        let sdt = e.tenCho;
                        if (e.soDienThoaiKhachHang) {
                            let s = e.soDienThoaiKhachHang.split("");
                            sdt = s[s.length - 3] + s[s.length - 2] + s[s.length - 1];
                        }
                        return {
                            id: e.id,
                            idTang: e.idTang,
                            KyHieu:
                                e.trangThai.idTrangThai == this.$t("trangThai.DaMua") ||
                                e.trangThai.idTrangThai == this.$t("trangThai.DaNgoi")
                                    ? sdt
                                    : e.tenCho,
                            MauNen: e.trangThai.maMau,
                            KhongDuocChon:
                                e.trangThai.idTrangThai ===
                                parseInt(this.$i18n.t("trangThai.DangThanhToan"))
                                    ? true
                                    : false,
                            // e.trangThai.idTrangThai != 1
                            //     ? e.trangThai.maMau
                            //     : e.loaiCho.maMau,
                            ChoDaChon: false,
                            HienThi: true,
                            TrangThai: e.trangThai.idTrangThai,
                            LoaiCho: e.loaiCho.id,
                            Gia: e.giaTien,
                            Dong: e.viTriHang,
                            Cot: e.viTriCot,
                        };
                    });
                    //
                    if (this.ChoTheoSdt.length > 0) {
                        let maTranChoTheoSdt = maTranCho.filter(
                            (e) =>
                                this.ChoTheoSdt.map((m) => m.guidChoNgoi).includes(
                                    e.id,
                                ) &&
                                this.ChoTheoSdt.map((m) => m.guidTang).includes(
                                    this.TangXe.idTang,
                                ),
                        );
                        this.data.DanhSachCho = maTranChoTheoSdt;
                        return;
                    }
                    //Giữ các chỗ đã chọn khi chuyển tầng
                    this.data.DanhSachCho = this.giuCacChoDaChonTruocDo(maTranCho);
                }, 100);
            },
            deep: true,
            immediate: true,
        },
        resetSoDoCho: {
            handler: function () {
                this.resetData();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.sodoghe-style {
    height: calc(100vh - 36px - 48px - 48px);
    width: 100%;
    position: relative;
}
.sodoghe-style .denxe {
    // background-image: url("../assets/images/DenXe.svg");
    background-repeat: no-repeat;
}
.thanhxe {
    position: absolute;
    top: 0;
    width: calc((100vh - 36px - 48px - 48px) / 1.618);
    max-width: calc(100vw - 32px);
    height: calc(100vh - 36px - 48px - 48px);
    border: 4px solid #aaa;
    border-radius: 16px;
    z-index: 0;
    background-color: #cccccc20;
}
.checkbox-style .dx-checkbox-icon {
    border: unset !important;
    background-color: white;
}
.checkbox-style .dx-checkbox-icon:before {
    color: #03a9f4;
}
.scroll-sodoghe-style::-webkit-scrollbar {
    display: none;
}
.volang,
.cuaravao {
    font-size: 4vh;
}
.rotate {
    transform: rotate(-90deg);
}
</style>
<style scoped>
>>> .checkbox-style .dx-checkbox-icon {
    border: unset !important;
    background-color: white !important;
}
>>> .checkbox-style .dx-checkbox-icon:before {
    color: #03a9f4;
}
.wheel-font-left {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 20%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-font-right {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 20%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.wheel-back-left {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 80%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-back-right {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 80%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.guong-trai {
    position: absolute;
    left: -2px;
    top: 4px;
    height: 24px;
    border-radius: 60px;
    border: 4px solid #ccc;
    border-top: 10px solid #ccc;
    padding: 2px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-35deg);
}
.guong-phai {
    position: absolute;
    right: -2px;
    top: 4px;
    height: 24px;
    border-radius: 60px;
    border: 4px solid #ccc;
    border-top: 10px solid #ccc;
    padding: 2px;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: rotate(35deg);
}
.buonglai {
    position: absolute;
    width: calc(100% - 12px);
    height: 20px;
    background: #dadce0;
    border-radius: 16px;
    top: 26px;
    left: 6px;
}
.donghoto {
    width: 16px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: -8px;
    left: 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.bangdientu {
    width: 24px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: -8px;
    left: 56px;
    border-radius: 2px;
}
.donghotrai {
    width: 12px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: -8px;
    left: -4px;
    border-radius: 50px;
}
.donghophai {
    width: 12px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: -8px;
    left: 32px;
    border-radius: 50px;
}
.ghelai {
    position: absolute;
    background: #fff;
    width: 40px;
    height: 24px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    bottom: -8px;
    z-index: 2;
}
.tayghelaixe {
    z-index: 0;
    width: calc(100% + 8px);
    height: 20px;
    background: #ddd;
    position: absolute;
    bottom: -12px;
    left: -4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.lungghelaixe {
    position: absolute;
    z-index: 2;
    background: #ddd;
    width: 24px;
    height: 4px;
    bottom: -8px;
    left: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.hang-xe {
    width: 100%;
    min-height: 40px;
    /* display: block; */
}
.hang-xe .vitri {
    margin: 0 8px;
    float: left;
    max-width: 48px;
}
</style>
