<template>
    <div>
        <div class="row pt-3 align-center justify-space-between">
            <div>Số điện thoại:</div>
            <div>{{model.soDienThoai}}</div>
        </div>
        <div class="row pt-3 align-center justify-space-between">
            <div>Họ và tên:</div>
            <div>{{model.hoTen}}</div>
        </div>
        <div class="row pt-3 align-center justify-space-between">
            <div>Vị trí chỗ:</div>
            <div class="success--text">{{model.soGhe}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        Data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            model: {
                soDienThoai: "",
                hoTen: "",
                soGhe: "",
            },
        };
    },
    mounted(){
        this.model = this.Data;
    },
    // watch: {
    //     Data() {
    //         if (this.Data) {
    //             this.model = this.Data;
    //         }
    //     },
    // },
};
</script>