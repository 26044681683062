<template>
    <div style="height: auto">
        <DxValidationGroup ref="formValidation">
            <div v-show="!chinhSuaSoatVe">
                <div
                    class="
                        row
                        justify-space-between
                        align-center
                        font-bold font-16
                        primary--text
                    "
                >
                    <div>
                        <a :href="`tel:${model.soDienThoai}`">
                            <div class="row align-center">
                                <i
                                    class="
                                        mdi mdi-card-account-phone-outline
                                        pr-3
                                    "
                                ></i>
                                <div>
                                    {{
                                        $Helper.formatPhone(
                                            model.soDienThoai
                                        ) || ""
                                    }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <ion-button
                        color="primary"
                        fill="clear"
                        @click="setChinhSuaSoatVe(true)"
                    >
                        <ion-icon :icon="pencil" class="font-24"></ion-icon>
                    </ion-button>
                </div>
                <div class="row pb-2 align-center font-bold font-16">
                    <i class="mdi mdi-account-circle-outline pr-3"></i>
                    <div>{{ model.hoTen || "" }}</div>
                </div>
                <div
                    class="row pb-2 align-center justify-space-between font-16"
                >
                    <div>
                        <div class="row align-center">
                            <i class="mdi mdi-google-maps pr-2"></i>
                            <div>Điểm xuống:</div>
                        </div>
                    </div>
                    <div class="font-bold">{{ model.tenDiemXuong || "" }}</div>
                    <!-- :style="`color:${
                        !chinhSuaSoatVe ? model.trangThaiTiemPhong.maMau : ''
                    }`" -->
                </div>
            </div>
            <div v-show="chinhSuaSoatVe">
                <div class="pb-3">
                    <div class="font-12 text-xs-left">Điện thoại</div>
                    <DxTextBox
                        class="mb-2"
                        v-model="model.soDienThoai"
                        validationMessageMode="always"
                        mode="tel"
                        :tabIndex="1"
                        ref="refTab1"
                        :onEnterKey="onEnterKey"
                        styling-mode="underlined"
                        valueChangeEvent="keyup"
                    >
                        <DxValidator>
                            <!-- <DxRequiredRule
                            message="Vui lòng nhập số điện thoại để tiếp tục mua vé!"
                        /> -->
                            <DxStringLengthRule
                                :max="13"
                                message="Số điện thoại không được vượt quá 13 ký tự!"
                            />
                            <DxNumericRule
                                message="Số điện thoại phải là số!"
                            />
                            <DxRangeRule
                                :min="0"
                                message="Số điện thoại phải là số!"
                            />
                            <DxPatternRule
                                :pattern="phonePattern"
                                message="Vui lòng nhập đúng định dạng số điện thoại để tiếp tục mua vé!"
                            />
                        </DxValidator>
                    </DxTextBox>
                </div>
                <div class="pb-3">
                    <div class="font-12 text-xs-left">Họ tên</div>
                    <DxTextBox
                        v-model="model.hoTen"
                        mode="text"
                        :tabIndex="2"
                        styling-mode="underlined"
                        :onEnterKey="onEnterKey"
                        ref="refTab2"
                        validationMessageMode="always"
                        ><DxValidator>
                            <DxStringLengthRule
                                :max="128"
                                message="Số điện thoại không được vượt quá 128 ký tự!" /></DxValidator
                    ></DxTextBox>
                </div>
                <div class="pb-1">
                    <div class="font-12 text-xs-left">Điểm xuống</div>
                    <DxSelectBox
                        v-model="model.diemXuong"
                        :items="data.diemXuong"
                        display-expr="tenDiemXuong"
                        value-expr="guidDiemXuong"
                        :onEnterKey="onEnterKey"
                        :search-enabled="true"
                        ref="refTab3"
                        :tabIndex="3"
                        styling-mode="underlined"
                        no-data-text="Không có dữ liệu!"
                    />
                    <GoiYVue
                        class="pt-2"
                        :data="GoiYDiemXuong"
                        :key="GoiYDiemXuong"
                        @ChonGoiY="ChonGoiYDiemXuong"
                    />
                </div>
            </div>
            <div
                :class="`row pb-2 align-center justify-space-between ${
                    chinhSuaSoatVe ? 'disabled--text' : ''
                }`"
            >
                <div>
                    <div class="row align-center">
                        <i class="mdi mdi-currency-usd pr-2"></i>
                        <div>Thanh toán:</div>
                    </div>
                </div>
                <div
                    :style="`color:${
                        !chinhSuaSoatVe ? model.trangThaiThanhToan.maMau : ''
                    }`"
                    :class="chinhSuaSoatVe ? 'disabled--text' : ''"
                >
                    {{ model.trangThaiThanhToan.tenTrangThai || "" }}
                </div>
            </div>
            <!-- <div
            :class="`row pb-2 align-center justify-space-between ${
                chinhSuaSoatVe ? 'disabled--text' : ''
            }`"
        >
            <div>
                <div class="row align-center">
                    <i class="mdi mdi-needle pr-2"></i>
                    <div>Vắc xin:</div>
                </div>
            </div>
            <div
                :style="`color:${
                    !chinhSuaSoatVe ? model.trangThaiTiemPhong.maMau : ''
                }`"
                :class="chinhSuaSoatVe ? 'disabled--text' : ''"
            >
                {{ model.trangThaiTiemPhong.tenTrangThai || "" }}
            </div>
        </div> -->
        </DxValidationGroup>
    </div>
</template>
<script>
import {
    DxTextBox,
    DxSelectBox,
    DxValidator,
    DxValidationGroup,
} from "devextreme-vue";
import GoiYVue from "../components/GoiY";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxNumericRule,
    DxPatternRule,
    DxRangeRule,
} from "devextreme-vue/data-grid";
import { pencil } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ref, reactive, onMounted } from "vue";
import { ChoNgoi } from "../../class/PhuongTien";
export default {
    components: {
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidationGroup,
        IonButton,
        IonIcon,
        GoiYVue,
        DxRangeRule,
    },
    props: {
        Data: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        let model = reactive({
            diemXuong: null,
            hoTen: "",
            lichSuSoatVe: [],
            maChuyenDi: "",
            maDatCho: "",
            soDienThoai: "",
            tenDiemXuong: "",
            guidDiemXuong: "",
            soGhe: "",
            soVe: "",
            trangThaiThanhToan: { maMau: "", tenTrangThai: "" },
            trangThaiTiemPhong: { maMau: "", tenTrangThai: "" },
        });
        let data = reactive({ diemXuong: [], goiYDiemXuong: [] });
        let phonePattern = ref(
            /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
        );
        let viTriDangChon = ref(null);
        if (props.Data) {
            model = props.Data;
        }
        return {
            pencil,
            model,
            data,
            phonePattern,
            viTriDangChon,
        };
    },
    computed: {
        ...mapGetters("Ve", ["chinhSuaSoatVe", "chuyenDiGanDay"]),
        GoiYDiemXuong() {
            return this.data.goiYDiemXuong;
        },
        diemXuongDangChon() {
            if (this.model.diemXuong) {
                let tmpItem = this.data.diemXuong.filter(
                    (e) => e.guidDiemXuong == this.model.diemXuong
                )[0];
                return tmpItem;
            }
            return {
                tenDiemXuong: this.model.tenDiemXuong,
                guidDiemXuong: this.model.guidDiemXuong,
            };
        },
    },
    methods: {
        ...mapMutations("Ve", ["setChinhSuaSoatVe"]),
        ...mapActions("Ve", [
            "layDSDiemDungTheoLoTrinh",
            "layGoiYDanhSachDiemXuongTheoChoNgoiChuyenDi",
            "layDiemXuongHienTaiCuaChoNgoiChuyenDi",
        ]),
        onEnterKey(e) {
            let index = e.event.currentTarget.tabIndex;
            if (index) {
                let ref = this.$refs["refTab" + (index + 1)];
                ref ? ref.instance.focus() : null;
            }
        },
        ChonGoiYDiemXuong(item) {
            this.model.diemXuong = item.guidDiemXuong;
        },
        async suaThongTin() {
            // if (!this.viTriDangChon) {
            //     console.log("loi viTriDangChon");
            //     return false;
            // }
            let rs = this.$refs.formValidation.instance.validate();
            if (rs.isValid) {
                let query = {
                    guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                    guidChoNgoi: this.viTriDangChon
                        ? this.viTriDangChon.id
                        : this.model.guidChoNgoi,
                    tenKhachHang: this.model.hoTen,
                    soDienThoai: this.model.soDienThoai,
                    tenDiemXuong: this.diemXuongDangChon.tenDiemXuong,
                    guidDiemXuong: this.diemXuongDangChon.guidDiemXuong,
                    maDatCho: this.model.maDatCho,
                };
                try {
                    this.$startLoading;
                    let rs = await $Core.Api.DonHang(
                        this.$t("url.LuuThongTinKhachHang")
                    ).Post(query);
                    this.$stopLoading;
                    this.$emit("rsSuaThongTinSoatVe", rs.StatusCode);
                } catch (error) {
                    this.$stopLoading;
                    this.$emit("rsSuaThongTinSoatVe", rs.StatusCode);
                }
            }
        },
    },
    async mounted() {
        setTimeout(() => {
            if (this.chinhSuaSoatVe) {
                let refSdt = this.$refs.refTab1.instance;
                if (refSdt) {
                    refSdt.focus();
                }
            }
        }, 500);
        let vtdc = await new ChoNgoi().viTriDangChon(true);
        this.viTriDangChon = vtdc;
        if (this.chinhSuaSoatVe) {
            //Lấy dsDiemDung
            try {
                let rs = await this.layDSDiemDungTheoLoTrinh(
                    this.chuyenDiGanDay.guidLoTrinh
                );
                if (rs.StatusCode == 200) {
                    this.data.diemXuong = rs.Data.data;
                    this.model.diemXuong = this.model.guidDiemXuong;
                    this.data.goiYDiemXuong = this.data.diemXuong.map((e) => {
                        e.text = e.tenDiemXuong;
                        e.id = e.guidDiemXuong;
                        return e;
                    });
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: ThongTinSoatVe.vue ~ line 280 ~ mounted ~ error",
                    error
                );
            }
            //Lấy dsChipDiemDung
            // try {
            //     if (!this.viTriDangChon) {
            //         console.log("loi viTriDangChon");
            //         return false;
            //     }
            //     let rs = await this.layGoiYDanhSachDiemXuongTheoChoNgoiChuyenDi(
            //         {
            //             guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
            //             guidChoNgoi: this.viTriDangChon.id,
            //         }
            //     );
            //     if (rs.StatusCode == 200) {
            //         this.data.goiYDiemXuong = rs.Data.data;
            //     }
            // } catch (error) {
            //     console.log(
            //         "🚀 ~ file: ThongTinSoatVe.vue ~ line 280 ~ mounted ~ error",
            //         error
            //     );
            // }
        }
        // try {
        //     let rs = await this.layDiemXuongHienTaiCuaChoNgoiChuyenDi({
        //         guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
        //         guidChoNgoi: this.viTriDangChon.id,
        //     });
        //     if (rs.StatusCode == 200) {
        //         this.model.diemXuong = rs.Data.data.guidDiemDung;
        //         this.model.tenDiemDung = rs.Data.data.tenDiemDung;
        //     }
        // } catch (error) {}
    },
};
</script>