<template>
    <div class="row py-1 align-center primary--text">
        <div class="xs2 align-center justify-center">
            <i class="mdi mdi-card-account-phone-outline"></i>
        </div>
        <div class="xs11">
            <div class="row justify-space-between align-center">
                <div>
                    {{ `${item.SoDienThoai} - ${item.Ten}` }}
                </div>
                <div class="font-16">
                    {{ $MoneyFormat(item.GiaVe) }}đ
                </div>
            </div>
        </div>
    </div>
    <div class="row py-1 align-center">
        <div class="xs2 align-center justify-center">
            <i class="mdi mdi-google-maps"></i>
        </div>
        <div class="xs11">
            <div class="row align-center">
                <div>
                    {{ item.BenDi }}
                </div>
                <i class="mdi mdi-arrow-right px-2"></i>
                <div>
                    {{ item.BenDen }}
                </div>
            </div>
        </div>
    </div>
    <div class="row py-1 align-center">
        <div class="xs2 align-center justify-center">
            <i class="mdi mdi-clock-time-four-outline"></i>
        </div>
        <div class="xs11">
            <div class="row justify-space-between align-center">
                <div>
                    {{ item.ThoiGianBatDau }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({
                SoDienThoai: "123",
                Ten: "123",
                GiaVe: "123",
                BenDi: "123",
                BenDen: "123",
                ThoiGianBatDau: "123",
            }),
        },
    },
};
</script>